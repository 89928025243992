export const HOME = "HOME"

export const DESIGNS = "DESIGNS"
// add comments later
export const LOGIN = "LOGIN"

export const CONTACT = "CONTACT"

export const SERVICES = "SERVICES"

export const ESTIMATES = "ESTIMATES"

export const CAREER = "CAREER"

export const ADMIN = "ADMIN"

export const BLOG = "BLOG"

export const PAYMENT_GATEWAY = "PAYMENT_GATEWAY"

export const PARTNER = "PARTNER"

export const TERMS = "TERMS"

export const PRIVACY = "PRIVACY"

export const PORTFOLIO = "PORTFOLIO"



/////////
export const ADD_EXPERIENCE = "ADD_EXPERIENCE"

export const ADD_MASSAGE_TYPE = "ADD_MASSAGE_TYPE"

export const ADD_INTENSITY = "ADD_LOOKING_FOR"

export const ADD_LOOKING_FOR = "ADD_LOOKING_FOR"

export const ADD_WHERESYOUR_PAIN = "ADD_WHERESYOUR_PAIN"


