export const designItems = [
    { id: 1, title: 'Law/Generic', imageUrl: 'https://imgur.com/RwN8TW7.png', description: 'Professional and authoritative, this design provides the perfect foundation for law firms or generic business services seeking a solid online presence.' },
    { id: 2, title: 'Retail/Modern', imageUrl: 'https://imgur.com/ocQPgGX.png', description: 'A sleek and modern retail template that captures the essence of contemporary shopping experiences, designed to attract the modern consumer.' },
    { id: 3, title: 'Retail Designer', imageUrl: 'https://imgur.com/BMEgrqs.png', description: 'Discover a fresh, dynamic retail design that combines elegance with functionality. Perfect for forward-thinking retail brands aiming to stand out.' },
    { id: 4, title: 'Tattoo/Body Art Modern', imageUrl: 'https://imgur.com/odHnMz4.png', description: 'A cutting-edge design that embodies the artistry of modern tattoo culture, ideal for studios wanting to showcase their creativity and skill.' },
    { id: 5, title: 'Real Estate Trending', imageUrl: 'https://imgur.com/QNy0QcO.png', description: 'This trending real estate design highlights luxury and innovation, perfect for real estate agencies that market a range of properties.' },
    { id: 6, title: 'Retail', imageUrl: 'https://imgur.com/lwZbXDF.png', description: 'Classic retail design meets modern web technology, creating a user-friendly experience that enhances customer engagement and sales.' },
    { id: 7, title: 'Technology Services', imageUrl: 'https://imgur.com/LyKt1tG.png', description: 'Futuristic and user-centric, this design is tailored for technology firms looking to highlight their services and innovations.' },
    { id: 8, title: 'Coffee Shop Modern', imageUrl: 'https://imgur.com/abnUTIE.png', description: "A contemporary coffee shop design that's as inviting as a freshly brewed cup, perfect for cafes looking to cultivate a cozy online nook." },
    { id: 9, title: 'Software as a Service', imageUrl: 'https://imgur.com/ZA90AXq.png', description: 'Streamlined and efficient, this SaaS design is built for optimal user experience, ensuring your software service shines online.' },
    { id: 10, title: 'Summer Camps', imageUrl: 'https://imgur.com/QHMruS6.png', description: 'Capturing the spirit of adventure, this design is ideal for summer camps or outdoor venues looking to attract and excite potential adventurers. ' },
    { id: 11, title: 'Outdoor Venues', imageUrl: 'https://imgur.com/rQinJeG.png', description: 'This design is adaptable, but could be ideal for outdoor venues looking to attract and excite potential adventurers.' },
    { id: 12, title: 'Resturants/Dinners', imageUrl: 'https://imgur.com/dKPkOJQ.png', description: 'For classic resturnats, this design serves up a delicious visual feast that will capture customer appetites.' },
    { id: 13, title: 'Automotive', imageUrl: 'https://imgur.com/Rp4ruwr.png', description: 'Sleek lines and dynamic layouts make this design perfect for automotive businesses aiming to accelerate their online presence.' },
    { id: 14, title: 'Real Estate Modern', imageUrl: 'https://imgur.com/eb3mF4D.png', description: 'Modern, clean, and sophisticated; ideal for real estate agents who want to present properties in their most flattering light.' },
    { id: 15, title: 'Fianacial Services', imageUrl: 'https://imgur.com/Ilz7ZiW.png', description: 'A trusted and secure design layout that speaks of reliability and professionalism, essential for any financial service provider. ' },
    { id: 16, title: 'Tattoo/Body Art Trending', imageUrl: 'https://imgur.com/ZyCn9a8.png', description: 'Vibrant and visually engaging, this design is tuned to the pulse of the latest trends in the tattoo and body art scene. ' },
    { id: 17, title: 'Autobody Modern', imageUrl: 'https://imgur.com/R5NhixX.png', description: 'Perfect for autobody shops, this design combines industrial chic with functionality, helping you attract more clients to your service.' },
    { id: 18, title: 'Incuencer Trending', imageUrl: 'https://imgur.com/aUy2bCi.png', description: 'Tailored for influencers, this design is all about making a personal brand pop, ensuring you shine online.' },
    { id: 19, title: 'Car Wash Modern', imageUrl: 'https://imgur.com/vvnKDCj.png', description: 'Make a splash with this modern car wash design, featuring engaging visuals and user-friendly interfaces to drive customer satisfaction.' },
    { id: 20, title: 'Resturnat/High-End', imageUrl: 'https://imgur.com/u1FoKrL.png', description: 'A sophisticated design for high-end dining experiences, setting the perfect scene for showcasing gourmet menus and exclusive services.' },
    { id: 21, title: 'Coffee Shop Trending', imageUrl: 'https://imgur.com/StFt12b.png', description: 'Trendy and inviting, this coffee shop design is perfect for establishments that want to create a warm, welcoming online cafe environment.' },
    // Add more design items as needed
  ];